<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
    saveGridState,
    loadGridState,
    connectToServerApi
} from "@/utils/agGridUtils";
import { useRouter } from "vue-router/auto";
import { getDefaultColDefs, getDefaultGridOptions } from '../../utils/aggridCommonConfigs.js';
import status from '../../components/schedule/statusRenderer.vue';
import { useThemeStore } from "@/stores/themeStore";
import SloActions from "@/components/special-order/SloActions.vue";
import SloFilters from "@/components/special-order/SloFilters.vue";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const router = useRouter();

const themeStore = useThemeStore();
const OUTBOUND_STAGED_URL = "/special_orders";
const GRID_SAVE_KEY = "slos_grid_state";

const showFilters = ref(true);
const gridLoaded = ref(false);

let gridApi;
let context = ref(null);

const advancedFilterKeys = ["id", "client.name", "week", "po_number", "sos_number", "store.id_number", "store.region_id",
    "total_qty", "order_status.name", "client_order_number", "delivery.id"];

const onGridReady = (params) => {
    gridApi = params.api;
    context.value = {
        apiUrl: OUTBOUND_STAGED_URL,
        advancedFilterKeys: advancedFilterKeys,
    };
    loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
    gridApi.setGridOption("serverSideDatasource", connectToServerApi());
    gridLoaded.value = true;
};

const onStateUpdated = function (params) {
    saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = getDefaultColDefs();

const columnDefs = [
    { field: "id", headerName: "LM#", colId: "id", cellDataType: "text", filter: false, headerCheckboxSelection: true, checkboxSelection: true, minWidth: 150 },
    { field: "client.name", headerName: "Client", minWidth: 250, colId: "client.name", cellDataType: "text" },
    { field: "week", headerName: "Week Ending", colId: "week", width: "350", filter: false, minWidth: 150 },
    { field: "po_number", headerName: "PO #", colId: "po_number", cellDataType: "text", filter: false, minWidth: 150 },
    { field: "sos_number", headerName: "SOS #", colId: "sos_number", cellDataType: "text", filter: false, minWidth: 150 },
    { field: "store.id_number", headerName: "Store #", colId: "store.id_number", cellDataType: "text", filter: false },
    { field: "store.region.name", headerName: "Region", colId: "store.region.name", cellDataType: "text", filter: false },
    { field: "total_qty", headerName: "Case Qty", colId: "total_qty", cellDataType: "number", filter: false },
    {
        field: "order_status.name", headerName: "Status", colId: "order_status.name", cellRenderer: status, minWidth: 150, filter: 'agSetColumnFilter', minWidth: 150,
        filterParams: {
            values: ["At Warehouse", "Labeled", "New", "Delivered", "Parcel Pack", "Shipped", "Billed", "Scheduled", "On Truck"],
        }
    },
    { field: "client_order_number", headerName: "Order #", colId: "client_order_number", cellDataType: "text", filter: false, minWidth: 150 },
    { field: "delivery.id", headerName: "Delivery #", colId: "delivery.id", cellDataType: "text", filter: false },
    { field: "", headerName: "Pickup", colId: "", cellDataType: "text", filter: false },
    {
        field: "action",
        filter: null,
        headerName: "Actions",
        sortable: false,
        colId: "Actions",
        cellRenderer: SloActions,
        minWidth: 200,
    }
];

const gridOptions = getDefaultGridOptions(columnDefs);

const navigateToUpload = () => {
    router.push("/special-orders/upload");
}

const rowDoubleClicked = function (event) {
    router.push(`/special-orders/${event.data.id}`);
};

</script>

<template>
    <div class="d-flex justify-space-between ml-2">
        <v-btn color="blue" size="small" variant="outlined" @click="showFilters = !showFilters"
            class="filter-btn ml-2 mt-1 mr-2" :append-icon="showFilters ? 'mdi-eye-off-outline' : 'mdi-eye-outline'">{{
                showFilters ? "Hide Filters" :
                    "Show Filters" }}</v-btn>
        <div class="d-flex">
            <v-btn size="small" color="primary" class="mr-6" @click="() => {
                router.push('special-orders/new') }">New</v-btn>
            <v-btn size="small" variant="outlined" class="mr-6" @click=" navigateToUpload ">Upload</v-btn>
        </div>
    </div>

    <div class="pa-4 d-flex">
        <SloFilters :grid-api="gridApi" v-if="showFilters && gridLoaded" class="mr-4" />
        <AgGridVue style="width: 100%; height: 900px;" :class="themeStore.agTheme" :columnDefs="columnDefs"
            :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context"
            @state-updated="onStateUpdated" @rowDoubleClicked="rowDoubleClicked"></AgGridVue>
    </div>
</template>